<template>
  <div>
    <!-- <page-header-back :backLink="backLink"></page-header-back> -->

    <v-container fluid>
      <v-row justify="center">
        <v-col sm="12" md="10" lg="10">
          <v-card flat>
            <v-toolbar flat></v-toolbar>
          </v-card>
          <v-form ref="form" lazy-validation>
            <v-card outlined tile class="mb-3">
              <v-card-title>
                {{ mode == 'EDIT' ? '학생 수정' : '학생 등록' }}
              </v-card-title>
              <v-divider/>
              <v-row class="mx-2 mb-3">
                <v-col cols="12">
                  <v-row align="center" justify="center" class="my-5">
                    <v-hover>
                      <template v-slot:default="{ hover }">
                        <v-progress-circular
                          v-if="uploading"
                          :size="100"
                          :width="15"
                          :rotate="360"
                          :value="progressUpload"
                          color="primary">
                          {{ progressUpload }}%
                        </v-progress-circular>

                        <v-avatar size="96" class="mx-3" v-else>
                          <v-img
                            v-if="imageUrl" :src="imageUrl"
                          ></v-img>
                          <v-img
                            v-else
                          ><v-icon size="96" color="grey lighten-1">mdi-account-circle</v-icon></v-img>

                          <v-fade-transition>
                            <v-overlay
                              v-if="hover"
                              absolute
                              color="#036358"
                            >
                              <v-btn  type="button" @click="onClickImageUpload"><v-icon>mdi-camera</v-icon></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                        </v-avatar>
                      </template>
                    </v-hover>
                  </v-row>
                  <!-- <input ref="imageInput" type="file" hidden @change="onChangeImages"> -->
                  <image-uploader
                    hidden
                    ref="imageInput"
                    :preview="true"
                    :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                    capture="environment"
                    :debug="0"
                    :autoRotate="true"
                    outputFormat="verbose"
                    @input="setImage"
                    :maxWidth="200"
                    :quality="0.7"
                    doNotResize="['gif', 'svg']"
                  ></image-uploader>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.name"
                    prepend-icon="mdi-tag"
                    label="성명"
                    placeholder="학생 이름을 입력하세요"
                    :rules="[rule.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.grade"
                    :items="gradeItems"
                    prepend-icon="mdi-numeric"
                    label="학년"
                    :rules="[rule.required]"
                  ></v-select>
                </v-col>
                <!-- <v-col cols="12" sm="6">
                  <v-row align="center" no-gutters>
                    <v-menu offset-y max-width="324">
                      <template v-slot:activator="{ on }">
                        <v-img>
                          <v-icon color="pink">{{ form.stickerIcon }}</v-icon>
                          <v-btn v-on="on" text height="70"><span class="subtitle-1 grey--text">스티커 모양 변경</span></v-btn>
                        </v-img>
                      </template>
                      <v-card
                        class="d-flex flex-wrap" max-width="324"
                      >
                        <v-card
                          v-for="(item, i) in emojis"
                          :key="i"
                          flat
                          class="pa-3"
                        >
                          <v-icon
                            size="30"
                            color="pink"
                            @click="clickEmoji(item.name)"
                          >{{ item.name }}</v-icon>
                        </v-card>
                      </v-card>
                    </v-menu>
                  </v-row>
                </v-col> -->
                <!-- <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.stickerCnt"
                    color="red darken-2"
                    prepend-icon="mdi-stamper"
                    label="스티커"
                    placeholder="스티커 갯수를 입력하세요"
                    :rules="[rule.required, rule.numberOnly, rule.stickerLimit]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.pointScore"
                    color="teal"
                    prepend-icon="mdi-file-powerpoint-box"
                    label="포인트"
                    placeholder="포인트 점수를 입력하세요"
                    :rules="[rule.required, rule.numberOnly]"
                  ></v-text-field>
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-select
                    v-model="form.status"
                    :items="statusItems"
                    prepend-icon="mdi-rotate-left"
                    :rules="[rule.required]"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-list-item class="pl-0">
                    <v-list-item-subtitle>
                      <v-icon class="mb-1">mdi-information-outline</v-icon>
                      학생이 접속할 계정을 설정합니다(선택)
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.userid"
                    prepend-icon="mdi-card-account-details"
                    label="로그인 아이디(예시, abc12345)"
                    placeholder="접속 아이디를 입력하세요"
                    :rules="[rule.idRule, rule.idLenRule]"
                    :counter="15"
                    autocomplete="off"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                    v-model="form.password"
                    prepend-icon="mdi-lock"
                    :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
                    label="로그인 패스워드"
                    placeholder="패스워드를 입력하세요"
                    :type="showPw ? 'text' : 'password'"
                    :rules="[rule.pwRule]"
                    @click:append="showPw = !showPw"
                  ></v-text-field>
                </v-col>
              </v-row>

            </v-card>
          </v-form>
          <v-card flat class="mt-5">
            <v-card-actions class="d-flex justify-center">
              <v-btn
              class="mb-5"
                text
                large
                @click="cancel"
              >취소</v-btn>
              <v-btn
                class="mb-5 mr-2"
                tile outlined
                color="primary"
                large
                @click="editStudent"
                v-if="mode == 'EDIT'"
              >수정</v-btn>
              <v-btn
                class="mb-5 mr-2"
                tile outlined
                color="primary"
                large
                @click="saveStudent"
                v-else
              >저장</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

  </div>
</template>
<script>
// import PageHeaderBack from '../PageHeaderBack'
import ImageUploader from 'vue-image-upload-resize'
import Utils from '@/utils'

export default {
  components: { ImageUploader },
  props: ['selItem', 'mode'],
  data () {
    return {
      backLink: '/manage/students',
      gradeItems: [
        '유치', '초1', '초2', '초3', '초4', '초5', '초6', '중1', '중2', '중3', '고1', '고2', '고3'
      ],
      rule: {
        required: v => !!v || '필수 항목입니다.',
        numberOnly: v => {
          v = Number(v)
          const pattern = /^[0-9]*$/
          return pattern.test(v) || '숫자만 입력하세요.'
        },
        stickerLimit: v => {
          const limit = Number(this.$store.state.totStickerCnt)
          v = Number(v)
          return (v < limit) || this.$store.state.totStickerCnt + '개 보다 작아야 합니다.'
        },
        pwRule: v => {
          const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.{8,})/
          return pattern.test(v) || '영문, 숫자 조합 8글자 이상이어야 합니다.'
        },
        idRule: v => {
          const pattern = /^[a-zA-Z][a-zA-Z0-9]*$/
          if (v.length > 0) {
            return pattern.test(v) || '영문, 숫자만 가능합니다.'
          } else {
            return true
          }
        },
        idLenRule: v => {
          const len = v.length
          if (len > 0 && len < 5) {
            return false || '5자리 이상입력하세요'
          } else if (len > 15) {
            return false || '15자리까지만 입력하세요'
          } else {
            return true
          }
        }
      },
      form: {
        name: '',
        grade: '유치',
        photoName: '',
        photoURL: '',
        stickerIcon: 'mdi-cat',
        stickerCnt: null,
        pointScore: null,
        academy: '',
        status: '정상',
        createdAt: null,
        userid: '',
        password: ''
      },
      imageUrl: '',
      emojis: [],
      progressUpload: 0,
      uploadTask: '',
      firebaseImageUrl: '',
      firebaseImageName: '',
      uploading: false,
      uploaded: false,
      statusItems: ['정상', '휴원', '퇴원'],
      hasImage: false,
      showPw: false
    }
  },
  watch: {
    uploadTask: function () {
      this.uploadTask.on('state_changed', sp => {
        this.progressUpload = Math.floor(sp.bytesTransferred / sp.totalBytes * 100)
      },
      null,
      () => {
        this.uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
          this.firebaseImageUrl = downloadURL
          this.uploading = false
          this.uploaded = true
          // this.$emit('downloadURL', downloadURL)
        })
      })
    }
  },
  created () {
    if (this.selItem) {
      this.imageUrl = this.selItem.photoURL

      // this.form.id = this.selItem.id
      this.form.name = this.selItem.name
      this.form.grade = this.selItem.grade
      this.form.photoName = this.selItem.photoName
      this.form.photoURL = this.selItem.photoURL
      this.form.stickerIcon = this.selItem.stickerIcon
      this.form.stickerCnt = this.selItem.stickerCnt
      this.form.pointScore = this.selItem.pointScore
      // this.form.academy = this.selItem.academy
      this.form.status = this.selItem.status
      // this.form.createdAt = this.selItem.createdAt
      this.form.userid = this.selItem.userid
      this.form.password = Utils.DEC(this.selItem.password)
    }

    this.read()
  },
  methods: {
    read () {
      this.emojis = []
      this.$firebase.firestore().collection('infos').doc('emojis').get()
        .then((r) => {
          // console.log(r.data().data.length)
          if (r.exists) {
            for (let i = 0; i < r.data().data.length; i++) {
              this.emojis.push(r.data().data[i])
            }
          }
        })
        .catch(e => {
          console.log(e.message)
        })
    },
    onClickImageUpload () {
      // this.$refs.imageInput.click()
      this.$refs.imageInput.$el.children[1].click()
    },
    setImage (f) {
      this.hasImage = true
      this.imageUrl = f.dataUrl

      this.upload(f)
    },
    clickEmoji (name) {
      this.form.stickerIcon = name
    },
    async saveStudent () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (await this.dupAccountCnt('ADD') > 0) {
        this.$toast.error('이미 사용중인 id 입니다.')
        return
      }

      this.form.academy = this.$store.state.user.uid
      this.form.createdAt = new Date()
      this.form.photoURL = this.firebaseImageUrl
      this.form.photoName = this.firebaseImageName
      this.form.userid = this.form.userid.trim()
      if (this.form.userid.length <= 0) {
        this.form.password = ''
      }
      // this.form.password = Utils.ENC(this.form.password)

      try {
        await this.$firebase.firestore().collection('students').add({
          name: this.form.name,
          grade: this.form.grade,
          photoName: this.form.photoName,
          photoURL: this.form.photoURL,
          stickerIcon: this.form.stickerIcon,
          stickerCnt: Number(this.form.stickerCnt),
          pointScore: Number(this.form.pointScore),
          academy: this.form.academy,
          status: this.form.status,
          createdAt: this.form.createdAt,
          userid: this.form.userid,
          password: Utils.ENC(this.form.password)
        })
        this.$router.push(this.backLink)
      } catch (e) {
        console.log(e.message)
      }
    },
    async editStudent () {
      if (!this.$refs.form.validate()) {
        return
      }

      if (this.uploaded) {
        this.form.photoURL = this.firebaseImageUrl
        this.form.photoName = this.firebaseImageName
      }

      if (await this.dupAccountCnt('EDIT') > 0) {
        this.$toast.error('이미 사용중인 id 입니다.')
        return
      }

      this.form.userid = this.form.userid.trim()
      if (this.form.userid.length <= 0) {
        this.form.password = ''
      }

      await this.$firebase.firestore().collection('students').doc(this.selItem.id).update({
        name: this.form.name,
        grade: this.form.grade,
        photoURL: this.form.photoURL,
        photoName: this.form.photoName,
        stickerIcon: this.form.stickerIcon,
        stickerCnt: Number(this.form.stickerCnt),
        pointScore: Number(this.form.pointScore),
        status: this.form.status,
        userid: this.form.userid,
        password: Utils.ENC(this.form.password)
      })
      this.$router.push(this.backLink)
    },
    upload (file) {
      const fileName = new Date().getTime()
      const ext = '.' + file.info.name.split('.')[1]
      try {
        const storageRef = this.$firebase.storage().ref()

        this.uploadTask = storageRef.child('images/' + this.$store.state.user.uid + '/' + fileName + ext).putString(file.dataUrl, 'data_url')
        this.firebaseImageName = fileName + ext
        this.uploading = true
      } catch (e) {
        console.log(e.message)
      }
    },
    cancel () {
      this.$router.push(this.backLink)
    },
    async dupAccountCnt (type) {
      let cnt = 0
      if (this.form.userid.trim().length <= 0) {
        return cnt
      }

      const sn = await this.$firebase.firestore().collection('students')
        .where('userid', '==', this.form.userid.trim()).get()

      if (sn.empty) {
        return cnt
      }

      if (type === 'ADD') {
        cnt = sn.size
      } else {
        sn.docs.forEach(doc => {
          if (doc.id !== this.selItem.id) {
            cnt = cnt + 1
          }
        })
      }

      return cnt
    }
  }
}
</script>
